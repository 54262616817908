<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        羽球GO 開創羽球新格局
      </div>
      <p>2023年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="羽球GO APP介紹">
        <p class="image_desc">羽球GO APP介紹</p>
        <p class="image_desc">全民打羽球-開創羽球新格局</p>
        <p class="image_desc">(圖片提供：本計畫)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          根據教育部體育署統計，全台打羽球人口超過300萬，為國人最多人接觸的運動項目之一，並且跟隨台灣羽球在東京奧運創下1金1銀的歷史佳績後，更帶動國內羽球運動熱潮，透過羽球GO APP能持續推廣羽球運動，達到運動健促的效果。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          本計畫協手久奈司體育事業與鍹鋐國際科技開發一同辦理「羽球GO」實證活動。
        </div>
        <div class="content-desc">於民國111年11月7日至12月31日為期2個月，共完成累計超過一千人次及一萬筆以上運動生理數據，完整數據留存原始資料於羽球GO APP中，並已與運動數據公益平台串接。</div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          透過羽球GO平台連結羽球賽事，後續收集比賽分數與當場競賽時間，便可分析選手競賽狀況，羽球GO平台可以幫助分析比賽狀況，也可以讓熱愛羽球的球友找比賽與場地進行臨打。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          透過羽球GO平台能夠讓用戶紀錄比賽數據，進而提升羽球技能，能讓熱愛羽球的球友隨時找比賽與隊友和場地進行臨打，推動羽球運動的發展。
        </div>
      </div>
    </section>

  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11109',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/111-09.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
